import React from "react";
import Img from "gatsby-image";
import styles from "./sponsors.module.css";

export default ({ data }) => (
  <div>
    <div className="text-center mt-4">
      <h4 className={styles.headline}>
        We are proud to have partners and 
        <br />
        teams that support this event year in year out.
      </h4>
    </div>

    <div className="row justify-content-center">
      <div className="col-md-9">
        <div className="row justify-content-center">
          {data[0].node.logos.map(item => {
            return (
              <div
                className={`col-md-1 col-sm-6 ${styles.image}`}
                key={item.title}
              >
                <Img alt="" sizes={item.sizes} />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  </div>
)
