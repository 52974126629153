import React from "react"
import Link from "gatsby-link"
import Img from "gatsby-image"
import Photos from "./photos"
import styles from "../sponsors.module.css";
import Container from "../container"
import ScrollAnimation from "react-animate-on-scroll"

export default ({ article }) => (

  <div>
      <ScrollAnimation animateIn="fadeIn">
          <div className="text-center">
            <div className={styles.headline} dangerouslySetInnerHTML={{
                    __html: article.description.childMarkdownRemark.html
                  }}>
            </div>
          </div>

          <div className="container">
            {/* <div className="row d-flex justify-content-center mb-4">
              <div className={`col-12 col-md-12 ${styles.winner_one}`}>
                <div className={`card ${styles.main_card}`}>
                    <img src="/ZepRe.png" alt="Faraja Rafting Challenge" />
                </div>
              </div>
            </div> */}
            <div className={`row`}>
              <div className={`col-12 col-md-3 ${styles.winner_three}`}>
                  <div className={`card ${styles.logo_card}`}>
                      <img src={article?.photos[4]?.sizes?.src} alt="Faraja Rafting Challenge" />
                  </div>
              </div>
              <div className={`col-12 col-md-3 ${styles.winner_three}`}>
                <div className={`card ${styles.logo_card}`}>
                    <img src={article?.photos[2]?.sizes?.src} alt="Faraja Rafting Challenge" />
                </div>
              </div>
              <div className={`col-12 col-md-3 ${styles.winner_two}`}>
                  <div className={`card ${styles.logo_card}`}>
                      <img src={article?.photos[3]?.sizes?.src} alt="Faraja Rafting Challenge" />
                  </div>
              </div>
              <div className={`col-12 col-md-3 ${styles.winner_two}`}>
                <div className={`card ${styles.logo_card}`}>
                    <img className="card-img-top" src={article?.photos[1]?.sizes?.src} alt="Faraja Rafting Challenge" />
                </div>
              </div>
              <div className={`col-12 col-md-12 ${styles.winner_two}`}>
                <div className={`card ${styles.logo_card}`}>
                    <img className="card-img-top" src="/NCBA-logo.jpg" alt="Faraja Rafting Challenge" />
                </div>
              </div>
            </div>
          </div>
      </ScrollAnimation>
  </div>
)
