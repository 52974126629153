import React from "react"
import Link from "gatsby-link"
import Img from "gatsby-image"
import Photos from "./photos"
import styles from "./home-section.module.css"
import Container from "../container"
import ScrollAnimation from "react-animate-on-scroll"
export default ({ article }) => (
  <div className={styles.spectator}>
    <Container>
      <ScrollAnimation animateIn="fadeIn">
        <div className={"container"}>
          <div className="text-center">
            <h3 className={`${styles.previewTitle}`}>{article.title}</h3>
          </div>
          <div className={"row"}>
            <div className={"col-12 col-md-6 col-lg-4 the_event_card"}>
              <div className={styles.the_event_card}>
                <span className={styles.number}>1 </span>
                <p>
                  Each team should consist of 5 people over the age of 13 years
                  old
                </p>
              </div>
            </div>
            <div className={"col-12 col-md-6 col-lg-4 the_event_card"}>
              <div className={styles.the_event_card}>
                <span className={styles.number}>2 </span>
                <p>
                  Due to the limited number of guides, we can only have up to 20
                  teams on each day
                </p>
              </div>
            </div>
            <div className={"col-12 col-md-12 col-lg-4 the_event_card"}>
              <div className={`${styles.the_event_card} third_card ${styles.third_card}`}>
                <span className={styles.number}>3 </span>
                <p>
                  No experience in rafting/swimming is required, qualified raft
                  guides are provided to each team
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="text-center">
          {/* <div
className={styles.description}
dangerouslySetInnerHTML={{
__html: article.description.childMarkdownRemark.html
}}
/> */}
        </div>
      </ScrollAnimation>
    </Container>
    <div className={`row`}>
      <div className="col-md-12 col-sm-12">
        {article.photos && (
          <div>
            {article.photos.map((item, index) => {
              return (
                <ScrollAnimation animateIn="fadeInUp" key={index}>
                  <div className={styles.spectatorImages}>
                    <div className="row justify-content-between no-gutters">
                      <div
                        className={`col-md-6 ${
                          index % 2 == 0 ? "order-md-2" : ""
                        } d-flex`}
                      >
                        {item.description && (
                          <h4
                            className="align-self-center"
                            dangerouslySetInnerHTML={{
                              __html: item.description
                            }}
                          />
                        )}
                      </div>
                      <div className={`col-md-6 `}>
                        {" "}
                        <Img alt={item.title} sizes={item.sizes} />
                      </div>
                    </div>
                  </div>{" "}
                </ScrollAnimation>
              )
            })}
          </div>
        )}
      </div>
    </div>
    <Container>
      <ScrollAnimation animateIn="fadeInUp">
        <div className={styles.package_card_wrap}>
          <div className={"row"}>
            <div className={"col-12 col-md-12"}>
              <h2>Spectating is free.</h2>
              <p>These are our spectating packages. </p>
            </div>
          </div>
          <div className={"row"}>
            <div className={"col-12 col-md-4"}>
              <div className={styles.package_card}>
                <div className={styles.package_card_banner}>
                  <span className={`${styles.package_card_icon}`}></span>
                </div>
                <h2>Kshs 3000</h2>
                <p>Includes the BBQ lunch and transport to & from the event</p>
              </div>
            </div>

            <div className={`${styles.silver} col-12 col-md-4`}>
              <div className={styles.package_card}>
                <div className={styles.package_card_banner}>
                  <span className={`${styles.package_card_icon}`}></span>
                </div>

                <h2>Kshs 2000</h2>
                <p>
                  Includes transport only. Snacks will be on sale in case you
                  want to have a family picnic.
                </p>
              </div>
            </div>

            <div className={`${styles.free} col-12 col-md-4`}>
              <div className={styles.package_card}>
                <div className={styles.package_card_banner}>
                  <span className={`${styles.package_card_icon}`}></span>
                </div>

                <h2>Self drive is free</h2>
                <p>
                  Self drive is FREE! Snacks will be on sale in case you want to
                  have a family picnic.
                </p>
              </div>
            </div>
          </div>
          <div className={"row"}>
            <div className={"col-12 pt-4"}>
              <p
                dangerouslySetInnerHTML={{
                  __html: article.body.childMarkdownRemark.html
                }}
              />
            </div>
          </div>
        </div>
      </ScrollAnimation>
    </Container>
  </div>
)
