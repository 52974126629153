import React from "react"
import Link from "gatsby-link"
import ScrollAnimation from "react-animate-on-scroll"
import Photos from "./photos"
import styles from "./home-section.module.css"
export default props => {
  const { article, data } = props
  return (
    <div className={`row justify-content-between ${styles.difference}`}>
      <div className="col-md-12 col-sm-12">
        <ScrollAnimation animateIn="fadeIn">
          <section id="makeDifference" className={styles.homeFundraiserSection}>
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-lg-12 col-md-12 col-sm-12 relative">
                  <ScrollAnimation animateIn="pulse" delay={500}>
                    <div className={styles.fundsContent}>
                      <h4>{data[0] && data[0].topHeadlineText}</h4>{" "}
                      <h3>{data[0] && data[0].heading}</h3>
                      <p>
                        {data[0] &&
                          data[0].bodyText.content[0] &&
                          data[0].bodyText.content[0].content[0] &&
                          data[0].bodyText.content[0].content[0].value}
                      </p>
                    </div>
                  </ScrollAnimation>

                  <ScrollAnimation animateIn="fadeInUp">
                    <div className={styles.topFunds}>
                      <p>Kshs 5.1m</p>
                      <p>Raised</p>
                      <p>
                        <span>22  teams! 400 spectators!</span>
                      </p>
                    </div>
                  </ScrollAnimation>

                  <ScrollAnimation animateIn="fadeIn">
                    <span className={styles.fundRaiser1}>
                      Gio Gio <strong>Kshs 1,421,000</strong>
                    </span>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="fadeIn ">
                    <span className={styles.fundRaiser2}>
                      Sayani Investments <strong>Ksh 722,800</strong>
                    </span>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="fadeIn">
                    <span className={styles.fundRaiser3}>
                    Knight Frank <strong> Kshs 204,000</strong>
                    </span>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="faddeIn">
                    <span className={styles.fundRaiser4}>
                      Anjarwalla and Khanna <strong> Kshs 245,000</strong>
                    </span>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="fadeIn">
                    <span className={styles.fundRaiser5}>
                      Armaan <strong> Kshs 110,600</strong>
                    </span>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </section>
        </ScrollAnimation>
        <div className="container">
          <div
            id={styles.makeDifferenceSection}
            className={`row justify-content-between`}
          >
            <div className="col-md-12 col-sm-12">
              <ScrollAnimation animateIn="fadeIn">
                {" "}
                <h3 className={styles.previewTitle}>{article.title}</h3>
              </ScrollAnimation>
              <ScrollAnimation animateIn="rotateInDownRight" animateOnce={true}>
                {" "}
                <div className={styles.paddle}>
                  {" "}
                  <img src="/paddle.png" alt="Paddle Image" />
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeIn">
                {" "}
                <div
                  className={styles.body}
                  dangerouslySetInnerHTML={{
                    __html: article.body.childMarkdownRemark.html
                  }}
                />
              </ScrollAnimation>
              <div className="col-md-8 col-sm-10">
                <ScrollAnimation animateIn="zoomIn">
                  {" "}
                  <Link to="/register-new/" className="btn">
                    <h2 style={{fontSize: 35}}>REGISTER TODAY!</h2>
                  </Link>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
