import React from "react"
import Link from "gatsby-link"
import BackgroundImage from "gatsby-background-image"
import styles from "./hero.module.css"
import ScrollAnimation from "react-animate-on-scroll"

class Hero extends React.Component {
  constructor() {
    super()
    this.state = { activeSlide: 0 }
  }
  componentDidMount() {
    // setTimeout(() => {
    //   this.start();
    // }, 2000)
  }

  start() {
    const { data } = this.props
    const { activeSlide } = this.state

    let time = 5000
    var newCount = activeSlide === data.length - 1 ? 0 : activeSlide + 1
    this.setState({ activeSlide: newCount }, () => {
      setTimeout(() => {
        this.start()
      }, time)
    })
  }

  componentWillUnmount() {
    clearTimeout()
  }

  render() {
    const { data, isHome } = this.props
    const { activeSlide } = this.state
    return (
      <div className={styles.hero}>
        <div className={styles.headline}>
          <ScrollAnimation animateIn="fadeIn">
            <h2>
              <font color="#2cbcf4">7<sup>th</sup> & 8<sup>th</sup></font> June <font color="#e4419e">2024</font>
            
                         </h2>
            <h3>
              <strong>At Savage Wilderness Camp. </strong>
            </h3>
            <p>
              <Link to="/register-new/" className="btn">
                REGISTER TODAY!
              </Link>
            </p>

            <a className={styles.scrollBtn} href="#makeDifference"></a>
          </ScrollAnimation>
        </div>
        <div className={`${styles.item}`}>
          <video width="100%" autoPlay="autoplay" loop muted>
            <source
              src="https://videos.ctfassets.net/snidcxsl43uf/1XXE7nAN1H0x2C738suKYv/e0ea175b06dd2bcc7b1d8815581ac5d9/rafting_website_farajagmail720p30__1_.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      </div>
    )
  }
}

export default Hero
